import React from 'react';
import { useTranslation } from 'react-i18next';

import Switch from '../../../application/components/formComponents/Switch';

const Settings = ({
    salesmanNewOrderMail,
    setSalesmanNewOrderMail,
    customerOrderCreatedMail,
    setCustomerOrderCreatedMail,
    customerOrderAcceptedMail,
    setCustomerOrderAcceptedMail,
    customerOrderRejectedMail,
    setCustomerOrderRejectedMail,
    onSubmit,
}) => {
    const { t } = useTranslation();

    return (

        <React.Fragment>
            <div className="col-12 mt-4">
                <Switch
                    id="active"
                    label={t('salesmanNewOrderMail')}
                    onChange={(id, e)=> setSalesmanNewOrderMail(e)}
                    value={salesmanNewOrderMail}
                />
            </div>
            <div className="col-12 mt-4">
                <Switch
                    id="active"
                    label={t('customerOrderCreatedMail')}
                    onChange={(id, e)=> setCustomerOrderCreatedMail(e)}
                    value={customerOrderCreatedMail}
                />
            </div>
            <div className="col-12 mt-4">
                <Switch
                    id="active"
                    label={t('customerOrderAcceptedMail')}
                    onChange={(id, e)=> setCustomerOrderAcceptedMail(e)}
                    value={customerOrderAcceptedMail}
                />
            </div>
            <div className="col-12 mt-4">
                <Switch
                    id="active"
                    label={t('customerOrderRejectedMail')}
                    onChange={(id, e)=> setCustomerOrderRejectedMail(e)}
                    value={customerOrderRejectedMail}
                />
            </div>

            <div className="col-12 text-center mt-5">
                <button onClick={onSubmit} id="adminClientsSave" className="btn btn--primary">{t('saveAction')}</button>
            </div>

        </React.Fragment>
    )
}

export default Settings;