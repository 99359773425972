import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';
import ProductPrice from '../../application/components/product/ProductPrice.component';
import './products.css';

const ProductsListView = ({ products, showPrices }) => {


    const renderProducts = () => {
        if (products) {
            return products.map(p => {
                return (
                    <div className="col-12" key={p._id}>
                        <div className="product-list__item rounded-box mb-5">
                            <Link to={`/product/${_.get(p, "variants[0]._id")}`} className="product-list__link position-absolute h-100 w-100 zi-1" />

                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="p-4 pt-5 position-relative">
                                        <p className="h3 product-list__title--primary"> {_.get(p, `name[${localStorage.getItem('language')}]`, _.get(p, "name.pl"))}</p>
                                        <ProductPrice
                                            discountedPrice={_.get(p, 'discountedPrice')}
                                            price={_.get(p, 'price')}
                                            currency={_.get(p, 'currency')}
                                            showPrices={showPrices}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            })
        }
    }
    return (
        <div id="listView" className="product-list product-list--list active">
            <div className="row">
                {renderProducts()}
            </div>
        </div>
    )
}

export default ProductsListView;
