import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectedProduct from './SelectedProduct.component';

const SelectedProducts = ({ selectedProducts, handleChangeProductPriceCurrency, handleChangeProductDiscount, handleDeleteSelectedProduct }) => {
    const { t } = useTranslation();

    const renderProducts = () => {
        if (!selectedProducts) return null;

        return selectedProducts.map((prod, i) => {
            // let selectedPricing;
            return (
                <SelectedProduct
                    key={i}
                    i={i}
                    prod={prod}
                    handleChangeProductPriceCurrency={handleChangeProductPriceCurrency}
                    handleChangeProductDiscount={handleChangeProductDiscount}
                    handleDeleteSelectedProduct={handleDeleteSelectedProduct}
                />
            )
        })
    }

    if (!selectedProducts || selectedProducts.length === 0) return null;

    return (
        <React.Fragment>
            <div className="col-12 mt-4">
                <div className="table-responsive">
                    <table className="table table-striped discounts__table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">{t('currency')}</th>
                                <th scope="col">{t('price')}</th>
                                <th scope="col">{t('discount')}</th>
                                <th scope="col">{t('deleteAction')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderProducts()}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SelectedProducts;