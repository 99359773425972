import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import SortUpIcon from '../../../assets/icons/sort-up.svg';
import SortDownIcon from '../../../assets/icons/sort-down.svg';

const SortListComponent = ({ fields, defaultSortBy, setFilters, defaultSortType }) => {

    const [sortBy, setSortBy] = useState('');
    const [sortType, setSortType] = useState(null);

    useEffect(() => {
        setSortBy(defaultSortBy);
    }, [defaultSortBy]);

    useEffect(() => {
        setSortType(defaultSortType);
    }, [defaultSortType]);

    const setSortByField = (fieldId) => {
        if (fieldId !== '') {
            let tmpSortType = _.clone(sortType);

            if (sortBy === fieldId) tmpSortType = tmpSortType * -1;
            else {
                setSortBy(fieldId);
                tmpSortType = 1;
            }

            const filters = {};
            filters.name = fieldId;
            filters.value = tmpSortType;
            // filters[`sort[${fieldId}]`] = tmpSortType;

            setSortType(tmpSortType);
            setFilters(filters);
        }
    }

    return (
        _.isArray(fields) && <tr>
            {/* <th>Lp.</th> */}
            {fields.map((field, i) => {
                return <th style={{ cursor: field.id !== '' ? 'pointer' : '' }} key={`header-${i}-${field.name}`} onClick={() => setSortByField(field.id)}>
                    {field.id === sortBy && <img alt="" src={sortType === 1 ? SortDownIcon : SortUpIcon} className="sortowanie mr-1" />}
                    {field.name}
                </th>
            })}
        </tr >
    )
}

export default SortListComponent;