import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

const AccountAddresses = ({ addresses, handleAddressEdit }) => {
    const { t } = useTranslation();

    const renderAddresses = () => {
        if (!addresses) return null;

        return addresses.map((a, i) => {
            return (
                <div className="col-12 mt-4" key={i}>
                    <div className="account__address">
                        <p className="account__label">{t('address')} {i + 1}</p>
                        <p className="account__value">
                            {_.get(a, 'street')} {_.get(a, 'streetNumber')}, {_.get(a, 'postCode')} {_.get(a, 'locality')}
                            <a id="editAddressOneTrigger" className="edit__trigger ml-3" href="/#"
                                onClick={() => handleAddressEdit(i)}
                                type="button"
                                //  data-toggle="collapse" data-target="#addAddress" aria-expanded="false" aria-controls="addAddress"
                            >{t('editAction')}</a>
                            </p>
                    </div>
                </div>
            )
        })
    }
    return (
        renderAddresses()
    )
}

export default AccountAddresses;