import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux'
import _ from 'lodash';


import Settings from '../../components/settings/Settings.component';

import { updateSettings } from '../../actions/settings/settings.actions';

const SettingsContainer = ({ settings, updateSettings }) => {
    const { t } = useTranslation();

    const [salesmanNewOrderMail, setSalesmanNewOrderMail] = useState(null);
    const [customerOrderCreatedMail, setCustomerOrderCreatedMail] = useState(null);
    const [customerOrderAcceptedMail, setCustomerOrderAcceptedMail] = useState(null);
    const [customerOrderRejectedMail, setCustomerOrderRejectedMail] = useState(null);

    useEffect(() => {
        setSalesmanNewOrderMail(_.get(settings, 'values[0].value'));
        setCustomerOrderCreatedMail(_.get(settings, 'values[1].value'));
        setCustomerOrderAcceptedMail(_.get(settings, 'values[2].value'));
        setCustomerOrderRejectedMail(_.get(settings, 'values[3].value'));
    }, [settings])


    const handleSaveSettings = () => {
        if (settings) {
            let newSettings = { ...settings };
            newSettings.values[0].value = salesmanNewOrderMail;
            newSettings.values[1].value = customerOrderCreatedMail;
            newSettings.values[2].value = customerOrderAcceptedMail;
            newSettings.values[3].value = customerOrderRejectedMail;

            updateSettings(_.get(settings, '_id'), newSettings.values);
            console.log('xddd')
        }
    }

    return (
        <div className="col">
            <div className="row">
                <div className="col-12">
                    <h1>{t('settings')}</h1>
                </div>
                {
                    settings &&
                    <Settings
                        salesmanNewOrderMail={salesmanNewOrderMail}
                        setSalesmanNewOrderMail={setSalesmanNewOrderMail}
                        customerOrderCreatedMail={customerOrderCreatedMail}
                        setCustomerOrderCreatedMail={setCustomerOrderCreatedMail}
                        customerOrderAcceptedMail={customerOrderAcceptedMail}
                        setCustomerOrderAcceptedMail={setCustomerOrderAcceptedMail}
                        customerOrderRejectedMail={customerOrderRejectedMail}
                        setCustomerOrderRejectedMail={setCustomerOrderRejectedMail}
                        onSubmit={handleSaveSettings}
                    />
                }

            </div>
        </div>
    )
}

const mapStateToProps = (store) => ({
    settings: _.find(_.get(store, 'app.settings'), (o) => {
        return o.type === 3
    }),
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateSettings: (id, values) => dispatch(updateSettings(id, values))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);