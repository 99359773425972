import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation} from "react-router-dom";

import { useTranslation } from 'react-i18next';
import './adminNav.css';

const AdminNav = ({pathname}) => {
    const { t } = useTranslation();
    const location = useLocation();
    
    const routes = [
        { href: "/admin/clients", label: t('clients') },
        { href: "/admin/sellers", label: t('sellers') },
        // { href: "/admin/dashboard", label: t('clientDashboard') }, 
        // { href: "/admin/languages", label: t('languages') },
        { href: "/admin/mailbox", label: t('mailbox') },
        { href: "/admin/stock", label: t('stocks') },
        { href: "/admin/pricings", label: t('pricings') },
        { href: "/admin/settings", label: t('settings') },
    ]
    const renderLinks = () => {
        return routes.map((r, i) => {
            let className = "nav__item";

            if(location.pathname === r.href){
                className = "nav__item active";
            }
            return (
                <li className={className} key={i}>
                    <Link className="nav__link" to={r.href}>{r.label}</Link>
                </li>
            )
        })

    }

    return (
        <div>
            {/* <!-- Desktop --> */}
            <ul className="nav flex-column d-none d-md-flex nav--desktop">
                {renderLinks()}
            </ul>

            {/* <!-- Mobile --> */}
            <div className="nav__trigger text-center d-block d-md-none">
                <button className="btn btn--primary">{t('categories')}</button>
            </div>
            <div className=" d-none nav--mobile">
                <ul className="nav flex-column">
                    <img src="../../img/icons/zamknij.svg" className="nav__close" alt="" />
                    {renderLinks()}
                </ul>
            </div>
        </div>
    )
}

export default AdminNav;