import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
    BrowserRouter as Router,
    Switch,
    useLocation
} from "react-router-dom";

import PrivateRoute from '../../application/components/routing/PrivateRoute.component';
import AdminNav from '../components/adminNav/AdminNav.component';
import AdminClientModule from './clients/AdminClientModule.container';
import AdminSellerModule from './sellers/AdminSellerModule.container';
import StockContainer from './stock/Stock.container';
import MailBoxContainer from './mailBox/MailBox.container';
import PricingContainer from './pricing/Pricing.container';
import PricingsContainer from './pricing/Pricings.container';
import SettingsContainer from './settings/Settings.container';

const AdminContainer = ({isLoading, user}) => {
    let location = useLocation();
    const [pathname, setPathname] = useState('');

    useEffect(() => {
        setPathname(location.pathname)
    }, [location.pathname]);

    return (
        <Router>
            <section id="adminClients" className="admin-clients content">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-auto">
                            <AdminNav
                                pathname={pathname}
                            />
                        </div>
                        <div className="col">
                            <div className="row">
                                <Switch>
                                    {/* Klienci */}
                                    <PrivateRoute path={["/admin/clients/:id", "/admin/clients"]} user={user} role='admin' isLoading={isLoading}>
                                        <AdminClientModule />
                                    </PrivateRoute>

                                    {/* Handlowcy */}
                                    <PrivateRoute path={["/admin/sellers/:id", "/admin/sellers"]} user={user} role='admin' isLoading={isLoading}>
                                        <AdminSellerModule />
                                    </PrivateRoute>

                                    {/* Ustawienia */}
                                    <PrivateRoute path='/admin/stock' user={user} role='admin' isLoading={isLoading}>
                                        <StockContainer />
                                    </PrivateRoute>
                                    <PrivateRoute path='/admin/mailbox' user={user} role='admin' isLoading={isLoading}>
                                        <MailBoxContainer />
                                    </PrivateRoute>
                                    <PrivateRoute path='/admin/pricings' exact={true} user={user} role='admin' isLoading={isLoading}>
                                        <PricingsContainer />
                                    </PrivateRoute>
                                    <PrivateRoute path="/admin/pricing/:id?" user={user} role='admin' isLoading={isLoading}>
                                        <PricingContainer />
                                    </PrivateRoute>
                                    <PrivateRoute path='/admin/settings' user={user} role='admin' isLoading={isLoading}>
                                        <SettingsContainer />
                                    </PrivateRoute>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Router>
    )
}

const mapStateToProps = (store) => ({
    isLoading: store.app.isLoading,
    user: store.app.user
});

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer);


// function PrivateRoute({ children, appIsLoading, isAuth, loggedUser, ...rest }) {
//     let isAllowed = appIsLoading || (isAuth && loggedUser && (loggedUser.type === "admin" || loggedUser.type === "freelancer"));
//     return (
//       <Route
//         {...rest}
//         render={({ location }) =>
//           isAllowed ? (
//             children
//           ) : (
//               <Redirect
//                 to={{
//                   pathname: "/",
//                   state: { from: location }
//                 }}
//               />
//             )
//         }
//       />
//     );
//   }

//   function AdminRoute({ children, appIsLoading, isAuth, loggedUser, ...rest }) {
//     let isAllowed = appIsLoading || (isAuth && loggedUser && loggedUser.type === "admin");
//     return (
//       <Route
//         {...rest}
//         render={({ location }) =>
//           isAllowed ? (
//             children
//           ) : (
//               <Redirect
//                 to={{
//                   pathname: "/",
//                   state: { from: location }
//                 }}
//               />
//             )
//         }
//       />
//     );
//   }