import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import _ from 'lodash'
import { connect } from 'react-redux';

import './sellerOrder.css';

import DatePicker from '../../application/components/datePicker/DatePicker.component';
import ConfirmationModal from '../../application/components/modal/ConfirmationModal.component';

import OrderInfo from '../components/OrderInfo.component';
import OrderProducts from '../components/OrderProducts.component';
import SellerSplitOrder from '../components/SellerSplitOrder.component';

import { getOrder, sendSplitOrder, acceptOrder, rejectOrder, clearOrder } from '../actions/sellerOrder.actions';


const SellerOrderContainer = ({ getOrder, order, sendSplitOrder, acceptOrder, rejectOrder, clearOrder }) => {
    const params = useParams();
    const { t } = useTranslation();

    const [showConfirmationAcceptModal, setShowConfirmationAcceptModal] = useState(false);
    const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] = useState(false);

    const [splitOrder, setSplitOrder] = useState([]);
    const [orderDate, setOrderDate] = useState(new Date());
    const [splitOrderDescription, setSplitOrderDescription] = useState('');
    useEffect(() => {
        if (params && params.id) {
            getOrder(params.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOrder])

    useEffect(() => {
        if (order && order.orderDate) {
            setOrderDate(new Date(order.orderDate))
        }
    }, [order])

    useEffect(() => {
        return () => {
            clearOrder();
        }
    }, [clearOrder])




    const handleToggleSplitOrder = () => {
        if (order && splitOrder.length === 0) {
            let newOrders = [];
            for (let i = 0; i <= 1; i++) {
                let orderTmp = Object.assign({}, order);
                orderTmp.products = [...orderTmp.products];
                newOrders.push(orderTmp);
            }

            setSplitOrder(newOrders);
            handleAddDescriptionToSplitOrders(_.get(order, 'description'))
        } else {
            setSplitOrder([])
        }
    }

    const handleAddSplitOrder = () => {
        if (!order) return;

        setSplitOrder([...splitOrder, order]);
    }

    const handleDeleteSplitOrder = (orderIndex) => {
        setSplitOrder([...splitOrder.slice(0, orderIndex), ...splitOrder.slice(orderIndex + 1)]);
    }

    const handleSelectProduct = (productIndex, orderIndex, orderChanges) => {
        const tmpOrders = [...splitOrder];
        setSplitOrder([
            ...tmpOrders.slice(0, orderIndex),
            {
                ...tmpOrders[orderIndex],
                products: [
                    ...tmpOrders[orderIndex].products.slice(0, productIndex),
                    { ...tmpOrders[orderIndex].products[productIndex], ...orderChanges },
                    ...tmpOrders[orderIndex].products.slice(productIndex + 1)
                ]
            },
            ...tmpOrders.slice(orderIndex + 1)
        ]);
    }

    const handleChangeSplitOrderDate = (orderIndex, date) => {
        const tmpOrders = [...splitOrder];
        setSplitOrder([
            ...tmpOrders.slice(0, orderIndex),
            { ...tmpOrders[orderIndex], orderDate: date },
            ...tmpOrders.slice(orderIndex + 1)
        ]);
    }

    const handleAddDescriptionToSplitOrders = (description) => {
        setSplitOrderDescription(description);
    }

    const handleSendSplitOrder = () => {
        if (!params || !params.id) return;

        let newOrders = _.cloneDeep(splitOrder);

        newOrders = newOrders.filter(o => {
            return o.products.length > 0;
        })

        newOrders.forEach(o => {
            o.description = splitOrderDescription;
            o.products = _.filter(o.products, (p) => {
                return p.checked;
            })
        })

        //Usuwamy puste zamowienia dodatkowe
        newOrders = newOrders.filter(o => {
            return o.products.length > 0;
        })

        if (newOrders.length === 0) {
            toast.error('Uzupełnij chociaż jedno zamówienie');
            return;
        }

        sendSplitOrder(newOrders, params.id);
    }

    const handleAcceptOrder = () => {
        if (!params || !params.id) return;

        setShowConfirmationAcceptModal(true);
    }
    const handleRejectOrder = () => {
        if (!params || !params.id) return;
        setShowConfirmationDeleteModal(true);
    }


    return (
        <section id="orderSeller" className="order-seller content">
            {
                showConfirmationAcceptModal &&
                <ConfirmationModal
                    text={t('confirmOrder')}
                    onConfirm={() => acceptOrder(params.id, orderDate)}
                    onCancel={() => setShowConfirmationAcceptModal(false)}
                />
            }

            {
                showConfirmationDeleteModal &&
                <ConfirmationModal
                    text={t('rejectOrder')}
                    onConfirm={() => rejectOrder(params.id)}
                    onCancel={() => setShowConfirmationDeleteModal(false)}
                />
            }

            <div className="container">

                {order && <OrderInfo
                    orderId={_.get(order, '_id')}
                    customer={_.get(order, 'customer.name')}
                    status={_.get(order, 'status')}
                />
                }

                {
                    order && order.status === 'new' &&
                    <div className="row mt-5">
                        <div className="col-12">
                            <h4>{t('shippingDate')}</h4>
                        </div>
                        <div className="col-auto mt-4">
                            <DatePicker
                                date={orderDate}
                                setDate={setOrderDate}
                            />
                        </div>
                    </div>
                }


                <div className="row mt-5">
                    <div className="col-12">
                        <div className="table-responsive">
                            {order && order.products &&
                                <OrderProducts
                                    products={order.products}
                                    status={_.get(order, 'status')}
                                />
                            }

                        </div>
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col-12">
                        {order && order.description &&
                            <div className="order__comment">
                                <h4>{t('orderDescription')}</h4>
                                <p>{order.description}</p>
                            </div>
                        }
                    </div>
                </div>


                {
                    order && order.status === 'new' &&
                    <div className="row mt-5">
                        <div className="col-auto">
                            <button id="orderAcceptTrigger" className="btn btn--primary" onClick={() => handleAcceptOrder()}>{t('accept')}</button>
                        </div>
                        <div className="col-auto mt-4 mt-md-0">
                            <button id="orderDeclineTrigger" className="btn btn--primary" onClick={() => handleRejectOrder()}>{t('reject')}</button>
                        </div>
                        <div className="col-auto mt-4 mt-md-0">
                            <button
                                id="orderSplitTrigger"
                                className="btn btn--primary"
                                data-toggle="collapse"
                                data-target="#orderSplit"
                                aria-expanded="false"
                                aria-controls="orderSplit"
                                onClick={() => handleToggleSplitOrder()}
                            >{t('splitOrder')}</button>
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col-12">
                        <SellerSplitOrder
                            orders={splitOrder}
                            handleSelectProduct={handleSelectProduct}
                            handleAddSplitOrder={handleAddSplitOrder}
                            handleDeleteSplitOrder={handleDeleteSplitOrder}
                            handleChangeSplitOrderDate={handleChangeSplitOrderDate}
                            handleAddDescriptionToSplitOrders={handleAddDescriptionToSplitOrders}
                            handleSendSplitOrder={handleSendSplitOrder}
                            description={splitOrderDescription}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    order: store.sellerOrder.order
});

const mapDispatchToProps = (dispatch) => {
    return {
        getOrder: (id) => { dispatch(getOrder(id)) },
        clearOrder: () => { dispatch(clearOrder()) },
        acceptOrder: (id, orderDate) => { dispatch(acceptOrder(id, orderDate)) },
        rejectOrder: (id) => { dispatch(rejectOrder(id)) },
        sendSplitOrder: (order, id) => { dispatch(sendSplitOrder(order, id)) },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerOrderContainer);