import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import ProductPrice from './ProductPrice.component';

const SelectVariant = ({ placeholder, userLanguage, options, currency, onChange, showPrices }) => {
    const { t } = useTranslation();

    const [showResult, setShowResult] = useState(false);
    const [query, setQuery] = useState('');

    const handleSelectVariant = (variant) => {
        onChange(variant);
        setQuery('');
    }


    const renderOptions = () => {
        if (options) {
            return options.map((o, i) => {
                if (_.includes(_.get(o, `name[${userLanguage}]`, o.name.pl), query)) {
                    return (
                        <div className="dropdown-item d-flex justify-content-between align-items-center"
                            style={{ width: '100%', height: 50, border: "1px solid rgba(0,0,0,0.2)", cursor: "pointer", paddingLeft: 16 }}
                            key={i}
                            onClick={() => handleSelectVariant(o)}
                        >
                            {o.avatar ? <img alt="" style={{ height: 40, border: "3px solid black" }} src={o.avatar} /> : null}

                            <div className="justify-content-center align-items-center" style={{ overflow: 'auto', maxWidth: '80%' }}>
                                <div className="price__label">{t('title')}</div>
                                <div style={{ overflow: 'auto' }}>  {_.get(o, `name[${localStorage.getItem('language')}]`, _.get(o, "name.pl"))} </div>
                            </div>


                            <div className="justify-content-end">
                                <p className="price__label">{t('price')}</p>
                                <ProductPrice
                                    discountedPrice={_.get(o, 'discountedPrice')}
                                    priceBeforeDiscount={_.get(o, 'priceBeforeDiscount')}
                                    price={_.get(o, 'price')}
                                    currency={currency}
                                    showPrices={showPrices}
                                    size={"small"}
                                />
                            </div>
                        </div>
                    )
                }
                return null;

            })
        }
    }

    return (
        <div className={`select d-block d-xl-block`}>
            <div className="dropdown dropdown--select ">
                <input
                    value={query}
                    type="text"
                    className={`dropdown-toggle border--light`}
                    onChange={(e) => setQuery(e.target.value)}
                    onFocus={() => setShowResult(true)}
                    onBlur={() => setTimeout(() => setShowResult(false), 150)}
                    placeholder={placeholder}
                    style={{
                        cursor: 'pointer'
                    }}
                />
                <span className="dropdown__chevron"
                    style={{
                        position: 'absolute',
                        right: 24,
                        top: 19
                    }}
                ></span>
                {showResult &&
                    <div style={{
                        position: 'absolute',
                        background: 'white',
                        width: '100%',
                        maxHeight: 300,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        zIndex: 100
                    }}>
                        {renderOptions()}
                    </div>
                }
            </div>
        </div>
    )
}

export default SelectVariant;