import React, { useEffect } from 'react';
import _ from 'lodash'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import './client.css'
const Client = (props) => {
    const { client, getClient } = props;
    const params = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (params && params.id) {
            getClient(params.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createAddressString = (address) => {
        return 'ul. ' + _.get(address, 'street') + ' ' + _.get(address, 'streetNumber') + ', ' + _.get(address, 'postCode') + ' ' + _.get(address, 'locality')
    }

    const renderAddresses = () => {
        if (!client || !client.addresses) return;

        return client.addresses.map((a, i) => {
            return (
                <div className="col-12 mt-4" key={i}>
                    <div className="client__address">
                        <p className="client__label">{t('address')} {i + 1}</p>
                        <p className="client__value">{createAddressString(a)}</p>
                    </div>
                </div>
            )
        })
    }

    return (
        <section id="client" className="client content">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>{t('client')}</h1>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="client__first-name">
                            <p className="client__label">{t('name')}</p>
                            <p className="client__value">{_.get(client, 'name', '-')}</p>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="client__last-name">
                            <p className="client__label">{t('surname')}</p>
                            <p className="client__value">{_.get(client, 'surname', '-')}</p>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="client__name">
                            <p className="client__label">{t('fullName')}</p>
                            <p className="client__value">{_.get(client, 'name', '-')}</p>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="client__mail">
                            <p className="client__label">E-mail</p>
                            <p className="client__value">{_.get(client, 'email', '-')}</p>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="client__phone">
                            <p className="client__label">{t('phone')}</p>
                            <p className="client__value">{_.get(client, 'phone', '-')}</p>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="client__nip">
                            <p className="client__label">{t('nip')}</p>
                            <p className="client__value">{_.get(client, 'NIP', '-')}</p>
                        </div>
                    </div>
                    {renderAddresses()}
                </div>
            </div>
        </section>
    )
}

export default Client;