import React from 'react';

import './footer.css'

import CloudLogo from '../../../assets/img/cloud-logo.png'

const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="footer__bottom pt-50 pb-50">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto">
                            <span className="mr-4">powered by</span><img src={CloudLogo} alt="" />
                        </div>
                        <div className="col-auto">
                            <span className="mr-1">infolinia: </span><span className="text--primary">+48 660 440 558</span>
                        </div>
                        <div className="col-auto">
                            <span className="mr-1">kontakt: </span><span><a href="mailto:it@over-cloud.pl" className="text--primary">it@over-cloud.pl</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer;