import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment'


import Oczekuje from '../../assets/icons/oczekuje.svg';
import Zamknij from '../../assets/icons/zamknij.svg';
import Check from '../../assets/icons/check.svg';

import SortListComponent from '../../application/components/sortList/SortList.component';

const Orders = ({ orders, setOrdersSort }) => {
    const { t } = useTranslation();

    const setBackgroundColor = (status) => {
        switch (status) {
            case 'completed':
                return "#c8f7a2";
            case 'rejected':
                return "#fdbbbb"
            default:
                return;
        }
    }

    const renderOrders = () => {
        if (!orders || orders.length === 0) return;

        return orders.map((o, i) => {
            const color = setBackgroundColor(o.status)
            return (
                <tr key={o._id} style={{ backgroundColor: color }}>
                    <td><Link to={`/order/${o._id}`}>{i + 1}. {_.get(o, 'orderNumber', o._id)}</Link></td>
                    <td className="d-flex justify-content-center align-items-center">
                        <Moment format="DD/MM/YYYY">
                            {_.get(o, 'createdAt')}
                        </Moment>
                    </td>
                    <td style={{ width: '10%' }}>
                        {renderOrderStatus(o.status)}
                    </td>
                </tr>
            )
        })
    }

    const renderOrderStatus = (status) => {
        switch (status) {
            case 'new':
                return (
                    <div className="order__status">
                        <img src={Oczekuje} alt="" className="mr-4" />
                        <span>{t('newOrder')}</span>
                    </div>
                )
            case 'rejected':
                return (
                    <div className="order__status">
                        <img src={Zamknij} alt="" className="mr-4" />
                        <span>{t('rejected')}</span>
                    </div>
                )
            case 'completed':
                return (
                    <div className="order__status">
                        <img src={Check} alt="" className="mr-4" />
                        <span>{t('completed')}</span>
                    </div>
                )
            default:
                return null;
        }
    }

    const fields = [
        { id: 'orderNumber', name: t('number') },
        { id: 'createdAt', name: t('creationDate') },
        { id: '', name: 'Status' },
    ]

    return (
        <div className="col-12 mt-5">
            <div className="table-responsive">
                {orders && orders.length > 0 &&
                    <table className="table table-striped">
                        <thead>
                            <SortListComponent
                                fields={fields}
                                defaultSortBy='createdAt'
                                setFilters={setOrdersSort}
                                defaultSortType={-1}
                            />
                        </thead>
                        <tbody>
                            {renderOrders()}
                        </tbody>
                    </table>}
            </div>
        </div>
    )
}

export default Orders;