import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';

import Product from '../components/Product.component';
import ProductCategories from '../../application/components/product/ProductCategories.component'
import { getProduct, getVariant, clearProduct } from '../actions/product.actions';
import { getCategoriesTree, chooseProductCategory, resetProductsCategory } from '../../products/actions/products.actions';
import { addProductToCart } from '../../cart/actions/cart.actions';

const ProductContainer = ({ history, userType,userLanguage, showPrices, clearProduct, getProduct, getVariant, variant, product, getCategoriesTree, productCategoriesTree, chooseProductCategory, resetProductsCategory, addProductToCart, stockSettings }) => {
    const params = useParams();

    const [productQuantity, changeProductQuantity] = useState(1);

    useEffect(() => {
        return () => {
            clearProduct()
        }
    }, [clearProduct])

    useEffect(() => {
        getCategoriesTree()
    }, [getCategoriesTree])

    useEffect(() => {
        if (variant && variant.productId) {
            getProduct(variant.productId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant])

    useEffect(() => {
        if (params && params.id) {
            getVariant(params.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getVariant])

    const handleAddQuantity = () => {
        changeProductQuantity(productQuantity + 1);
    }
    const handleSubtractQuantity = () => {
        if (productQuantity === 1) return;
        changeProductQuantity(productQuantity - 1);
    }

    const handleChangeVariant = (variant) => {
        if (!variant && !variant._id) return;

        history.push(`${variant._id}`)

        getVariant(variant._id);
    }




    return (
        <section id="product" className="product content">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-auto">
                        <ProductCategories
                            productCategoriesTree={productCategoriesTree}
                            chooseProductCategory={chooseProductCategory}
                            resetProductsCategory={resetProductsCategory}
                        />
                    </div>
                    <Product
                        product={product}
                        variant={variant}
                        handleAdd={handleAddQuantity}
                        handleSubtract={handleSubtractQuantity}
                        handleChangeVariant={handleChangeVariant}
                        productQuantity={productQuantity}
                        addProductToCart={addProductToCart}
                        stockSettings={stockSettings}
                        userType={userType}
                        showPrices={showPrices}
                        userLanguage={userLanguage}
                    />
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    history: store.app.history,
    product: store.product.product,
    variant: store.product.variant,
    productCategoriesTree: store.products.productCategoriesTree,
    stockSettings: _.find(_.get(store, 'app.settings'), (o) => {
        return o.type === 2
    }),
    userType: _.get(store, 'app.user.type'),
    showPrices: _.get(store, 'app.user.showPrices'),
    userLanguage: _.get(store, 'app.user.language', 'pl'),
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProduct: (id) => dispatch(getProduct(id)),
        getVariant: (id) => dispatch(getVariant(id)),
        clearProduct: () => dispatch(clearProduct()),
        getCategoriesTree: () => dispatch(getCategoriesTree()),
        chooseProductCategory: (id) => dispatch(chooseProductCategory(id)),
        resetProductsCategory: () => dispatch(resetProductsCategory()),
        addProductToCart: (product, amount) => dispatch(addProductToCart(product, amount)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);